<template>
  <div class="partner-reward" :class="{'suwon-map' : computedSuwonMapContest}" :id="`${component.name}PartnerReward`">
    <div class="header" :class="{skeleton:!state.loaded.header}" :style="{color: state.partnerShipDetail.topColorText, backgroundColor: state.partnerShipDetail.topColorBg}">
      <div class="container">
        <div class="row">
          <div class="left col-lg-3">
            <div class="poster">
              <img :src="computedThumbnailUrl"/>
            </div>
            <div class="share">
              <button @click="share(s.name)" :disabled="!state.loaded.header" v-for="(s, idx) in shares" :key="idx">
                <img :src="state.partnerShipDetail.topColorText === '#ffffff' ? s.ico.white : s.ico.black" :title="s.title"/>
              </button>
            </div>
          </div>
          <div class="right col-lg-9">
            <div class="wrapper">
              <div class="title">
                <b>{{ state.partnerShipDetail.contestName }}</b>
              </div>
              <div class="duration" v-if="state.partnerShipDetail.contestType !== 'E'">
                <span>{{ state.partnerShipDetail.fundingStartDate }} ~ {{ state.partnerShipDetail.fundingEndDate }}</span>
              </div>
              <div class="desc">
                <span v-html="state.partnerShipDetail.contestExplanationText"></span>
                <div class="pt-15" v-if="$route.params.projectType === 'mock' && state.mockContestInfo">
                  <b>모의투자 한도: {{ $lib.getNumberFormat(state.mockContestInfo.investPossibleLimit) }}{{ computedCurrencyUnit }}</b>
                  <b v-if="$store.state.account.loggedIn"> / 나의 투자: {{ $lib.getNumberFormat(state.mockContestInfo.myContestInvestAmt) }}{{ computedCurrencyUnit }}</b>
                </div>
              </div>
              <div class="logos">
                <img :src="l" v-for="(l, idx) in state.partnerShipDetail.partnerLogoPaths" :key="idx"/>
              </div>
              <div class="map" v-if="computedSuwonMapContest">
                <img src="/assets/img/page.partnership.detail.suwon.map.pd.jpg" alt="수원시 지도" title="수원시 지도" usemap="#suwon-map" v-if="$route.query.tag === '팔달구'"/>
                <img src="/assets/img/page.partnership.detail.suwon.map.yt.jpg" alt="수원시 지도" title="수원시 지도" usemap="#suwon-map" v-else-if="$route.query.tag === '영통구'"/>
                <img src="/assets/img/page.partnership.detail.suwon.map.ks.jpg" alt="수원시 지도" title="수원시 지도" usemap="#suwon-map" v-else-if="$route.query.tag === '권선구'"/>
                <img src="/assets/img/page.partnership.detail.suwon.map.jpg" usemap="#suwon-map" v-else/>
                <map name="suwon-map" id="suwon-map">
                  <area alt="권선구" title="권선구" :href="`/partnership/${computedSuwonContestSeq}/reward?tag=권선구`" shape="poly"
                        coords="86,102,38,105,24,161,38,195,59,191,72,228,94,248,122,239,130,248,172,252,190,234,202,212,194,193,146,182,117,168,119,148,106,131"/>
                  <area alt="팔달구" title="팔달구" :href="`/partnership/${computedSuwonContestSeq}/reward?tag=팔달구`" shape="poly"
                        coords="186,119,205,139,201,161,204,175,193,183,186,196,179,198,172,188,132,180,122,170,113,170,118,150,104,130,110,127,150,134"/>
                  <area alt="영통구" title="영통구" :href="`/partnership/${computedSuwonContestSeq}/reward?tag=영통구`" shape="poly"
                        coords="202,74,186,100,185,120,204,142,201,160,203,174,193,186,200,198,203,214,196,230,207,240,216,236,221,244,227,239,228,234,239,225,237,222,247.00000190734863,212,261.00000190734863,211,264.00000190734863,204,263.00000190734863,200,267.00000190734863,191,262.00000190734863,186,271.00000190734863,176,268.00000190734863,167,253.00000190734863,174,251.00000190734863,181,250.00000190734863,173,244.00000190734863,172,236.00000190734863,167,241.00000190734863,156,240.00000190734863,154,271.00000190734863,140,275.00000190734863,136,271.00000190734863,128,275.00000190734863,119,273.00000190734863,115,269.00000190734863,113,259.00000190734863,120,248.00000190734863,120,242.00000190734863,113,240.00000190734863,114,235.00000190734863,123,229.00000190734863,115,232.00000190734863,104,226.00000190734863,102,232.00000190734863,96,231.00000190734863,85,225.00000190734863,79,210.00000190734863,80"/>
                </map>
              </div>
            </div>
            <select class="font-sm form-control border-focus-point" v-if="computedPartnerContestList.length" @change="move($event)">
              <option value="">연결 파트너십</option>
              <option :value="c.contestSeq" v-for="(c, idx) in computedPartnerContestList" :key="idx">{{ c.contestName }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="article" :class="{skeleton:!state.loaded.projects.init}">
      <div class="status clearfix">
        <div class="part">
          <span class="img" style="background-image: url(/assets/ico/page.partnership.project.svg)"></span>
          <div class="subject">
            <span>프로젝트</span>
          </div>
          <div class="cnt">
            <b class="color-point">{{ $lib.getNumberFormat(state.partnerShipDetail.projectCount) }}</b>
            <span>개</span>
          </div>
        </div>
        <div class="part">
          <span class="img" style="background-image: url(/assets/ico/page.partnership.people.svg)"></span>
          <div class="subject">
            <span>후원자</span>
          </div>
          <div class="cnt">
            <b class="color-point">{{ $lib.getNumberFormat(state.partnerShipDetail.investorCount) }}</b>
            <span>명</span>
          </div>
        </div>
        <div class="part">
          <span class="img" style="background-image: url(/assets/ico/page.partnership.amount.svg)"></span>
          <div class="subject">
            <!-- #1147 -->
            <span v-if="state.contestSeq === '752'">모집 새싹</span>
            <span v-else>모집 금액</span>
          </div>
          <div class="cnt">
            <b class="color-point">{{ $lib.getNumberFormat(state.partnerShipDetail.totalAmt) }}</b>
            <span>{{ computedCurrencyUnit }}</span>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="container">
          <div></div>
        </div>
      </div>
      <div class="tabs" v-if="state.tabs.length > 1">
        <div class="container">
          <ul class="tight">
            <li :class="{ 'active color-point': $route.params.projectType === t.name }" v-for="(t, idx) in state.tabs" :key="idx">
              <router-link :to="`/partnership/${state.contestSeq}/${t.name}${$route.query.preview === 'Y' ? '?preview=Y' : ''}`" @click.native="change(t.name)">{{ t.title }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="tags" v-if="state.contestTagFilterList.length">
        <div class="container">
          <ul class="tight">
            <li v-for="(t, idx) in state.contestTagFilterList" :key="idx">
              <a class="btn pointer" :class="(t.tagFilterName === '전체' ? !state.args.tagFilterName : t.tagFilterName === state.args.tagFilterName) ? 'btn-bordered-point' : 'btn-default'" @click="filter(t.tagFilterName)">
                {{ t.tagFilterName }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="banner" v-if="state.contestTagFilterList.length >= 2">
        <div class="container">
          <span class="img" :style="{backgroundImage: `url('${computedTagFilter.tagBannerFilePath + computedTagFilter.tagBannerFileSaveName}')`}"></span>
        </div>
      </div>
      <div class="cards" :class="{ hexa: state.contestSeq === '752' }" ref="cardsRef">
        <div class="container">
          <div class="acts clearfix">
            <div class="wrapper">
              <div class="side left font-sm" v-if="$route.params.projectType !== 'mock' && state.partnerShipDetail.dashboardUseYn ==='Y' && state.loaded.header">
                <a class="pointer-u font-sm" @click="showMap()">지역별 참여 현황 보기</a>
              </div>
              <div class="side right font-sm" :class="{'hide': state.mockContestInfo && state.mockContestInfo.projectStateHideYn === 'Y'}">
                <div class="dropdown pointer" title="정렬">
                  <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ computedSorting }}</span>
                  <div class="dropdown-menu">
                    <a class="dropdown-item font-sm" :class="{ on: s.name === state.args.sorting }" @click="sort(s.name)" v-for="(s, idx) in sorts" :key="idx">{{ s.title }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="state.loaded.projects.init">
            <ul class="tight clearfix" v-if="state.project.list.length">
              <li v-for="(p, idx) in state.project.list" :key="idx">
                <CombinedCard
                    :projectSeq="Number(p.projectSeq)"
                    :projectType="p.projectType"
                    :link="p.projectType === 'loan' ? p.url : `/${p.projectType === 'mock' ? 'reward' : p.projectType}/${p.projectSeq}`"
                    :thumbFilePath="p.projectType === 'loan' ? p.filePath : p.thumbFilePath"
                    :amount="Number(p.progressOrder === 1 ? p.projectType === 'reward' ? p.expenseAmt : p.expenseAmt : p.expenseAmt)"
                    :targetAmt="Number(p.targetAmt)"
                    :projectName="p.projectName"
                    :progressOrder="Number(p.progressOrder)"
                    :simpleText="(p.projectType === 'invest' || p.projectType === 'loan') ? undefined : p.simpleText"
                    :projectCate="p.projectCateName"
                    :fundingType="p.fundingType"
                    :count="Number(p.investorCount)"
                    :percent="p.projectType === 'loan' ? Number(p.per) : p.investRate"
                    :dday="Number(p.dday)"
                    :builderName="p.builderName"
                    :builderSeq="p.builderSeq"
                    :successFailName="p.projectType === 'loan' ? p.successFailFlag === 'S' ? '성공' : '마감' : p.successFailName"
                    :hideInterest="p.projectType === 'loan'"
                    :hideState="p.projectStateHideYn === 'Y'"
                    :mainImagePosition="p.mainImagePosition"
                    :contestSeq="state.contestSeq"
                    :currencyUnit="computedCurrencyUnit"
                    :stockTypeText="p.stockTypeText"
                    :rewardFlag="p.rewardFlag"
                    :incomeDeductionFlag="p.incomeDeductionFlag"
                    :privateEquityApprovedCnt="p.privateEquityApprovedCnt"
                    :thumbRatioToSquare="true"
                />
              </li>
            </ul>
            <div class="pt-30 pb-30" v-else>
              <NoData/>
            </div>
            <ul class="tight clearfix" v-if="!state.loaded.projects.more">
              <li v-for="i in 3" :key="i">
                <CombinedCard :skeleton="true" builderName="Wait a moment" projectName="Wait" projectCate="Wait"
                              simpleText="Wait" fundingType="A" :progressOrder="1" :count="100" :amount="100" :percent="10"
                              :thumbRatioToSquare="true"/>
              </li>
            </ul>
            <!--            <div class="more"-->
            <!--                 v-if="state.loaded.projects.init && $route.params.projectType !== 'loan'-->
            <!--                 && state.project.list.length && state.project.list.length < ($route.params.projectType === 'invest' ? state.investProjectTotCnt : $route.params.projectType === 'reward' ? state.rewardProjectTotCnt : $route.params.projectType === 'mock' ? state.mockProjectTotCnt : 0)">-->
            <!--              <button class="btn btn-default font-sm" @click="load()" :disabled="!state.loaded.projects.more">+ 더 보기</button>-->
            <!--            </div>-->
          </template>
          <ul class="tight clearfix" v-else>
            <li v-for="i in 3" :key="i">
              <CombinedCard :skeleton="true" builderName="Wait a moment" projectName="Wait" projectCate="Wait"
                            simpleText="Wait" fundingType="A" :progressOrder="1" :count="0" :amount="0" :percent="0"
                            :thumbRatioToSquare="true"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import router from "@/scripts/router";
import store from "@/scripts/store";
import Card from "@/components/Card";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import env from "@/scripts/env";
import CombinedCard from "@/components/CombinedCard.vue";

function Component(initialize) {
  this.name = "pagePartnerReward";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {CombinedCard, NoData, Card},
  setup() {
    const component = new Component(() => {
      init();
    });

    const state = reactive({
      contestSeq: "",
      loaded: {
        header: false,
        completed: false,
        projects: {
          init: false,
          more: true
        },
      },
      rewardProjectTotCnt: 0,
      investProjectTotCnt: 0,
      mockProjectTotCnt: 0,
      loanProjectTotCnt: 0,
      loanSummary: {},
      partnerContestList: [],
      contestTagFilterList: [],
      tabs: [],
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 12,
        projectType: "reward",
        sorting: "amount",
        tagFilterName: ""
      },
      partnerShipDetail: {
        contestName: "Please wait a moment",
        contestExplanationText: "Please wait a moment",
        topColorBg: "",
        topColorText: "",
        fundingStartDate: "0000년 00월 00일",
        fundingEndDate: "0000년 00월 00일",
        thumbImagePath: "",
        posterImagePath: "",
        partnerLogoPaths: [],
        projectCount: 0,
        investorCount: 0,
        dashboardUseYn: "",
        contestType: "",
      },
      mockContestInfo: {
        investPossibleLimit: "",
        currencyUnit: "",
      },
      project: {
        count: 0,
        list: []
      }
    });

    const cardsRef = ref();

    const shares = [{
      name: "facebook",
      title: "페이스북으로 공유하기",
      ico: {
        black: "/assets/ico/page.partnership.reward.sns.facebook.svg",
        white: "/assets/ico/page.partnership.reward.sns.facebook.white.svg",
      }
    }, {
      name: "twitter",
      title: "트위터로 공유하기",
      ico: {
        black: "/assets/ico/page.partnership.reward.sns.twitter.svg",
        white: "/assets/ico/page.partnership.reward.sns.twitter.white.svg",
      }
    }, {
      name: "kakaolink",
      title: "카카오톡으로 공유하기",
      ico: {
        black: "/assets/ico/page.partnership.reward.sns.kakaotalk.svg",
        white: "/assets/ico/page.partnership.reward.sns.kakaotalk.white.svg",
      }
    }, {
      name: "band",
      title: "밴드로 공유하기",
      ico: {
        black: "/assets/ico/page.partnership.reward.sns.band.svg",
        white: "/assets/ico/page.partnership.reward.sns.band.white.svg",
      }
    }];

    const computedCurrencyUnit = computed(() => {
      return state.mockContestInfo.currencyUnit || "원";
    });

    const computedSorting = computed(() => {
      return sorts.find(s => s.name === state.args.sorting)?.title;
    });

    const computedTagFilter = computed(() => {
      const idx = state.contestTagFilterList.map(f => f.tagFilterName).indexOf(state.args.tagFilterName);

      if (idx <= 0) {
        return state.contestTagFilterList[state.contestTagFilterList.length === 2 ? 1 : 0];
      }

      return state.contestTagFilterList[idx];
    });

    // 2022년 수원시 도시재생 크라우드펀딩
    const computedSuwonContestSeq = computed(() => {
      return ["local", "dev"].includes(env.location) ? "572" : "650";
    });

    const computedSuwonMapContest = computed(() => {
      return state.contestSeq === (["local", "dev"].includes(env.location) ? "572" : "650");
    });

    const computedThumbnailUrl = computed(() => {
      return store.getters.thumbnailUrl(state.partnerShipDetail.posterImagePath, {skipSize: true});
    });

    const computedPartnerContestList = computed(() => {
      return state.partnerContestList.filter(c => Number(c.contestSeq) !== Number(state.contestSeq));
    });

    const showMap = () => {
      store.commit("openModal", {
        name: "PartnerRegion",
        params: {
          detail: state.partnerShipDetail
        }
      });
    };

    const sorts = [
      {name: "amount", title: "모집금액순"},
      {name: "largest", title: "인기순"},
      {name: "latest", title: "최신순"},
      {name: "success", title: "성공펀드"}
    ];

    const sort = (sorting) => {
      state.args.sorting = sorting;
      // load(true);
      init();
    };

    const filter = (filterName) => {
      const path = router.app.$route.path;
      const query = lib.getRenewed(router.app.$route.query);
      const tag = filterName === "전체" ? "" : filterName;

      if (tag === query.tag) {
        return;
      }

      query.tag = tag;

      if (!query.tag) {
        delete query.tag;
      }

      router.push({path, query});
    };

    const move = (e) => {
      const projectType = router.app.$route.params.projectType !== "mock" ? "reward" : "mock";
      router.push({path: `/partnership/${e.target.value}/${projectType}`});
      store.commit("refresh");
    };

    const load = (init) => {
      if (init) {
        state.loaded.completed = false;
        state.loaded.projects.init = false;
        state.loaded.projects.more = true;
        state.args.pageIndex = 1;
      } else if (state.loaded.completed) {
        return;
      } else {
        state.loaded.projects.more = false;
        state.args.pageIndex += 1;
      }

      state.args.projectType = router.app.$route.params.projectType;

      http.get(`/api/partnerships/${state.contestSeq}/projects`, state.args, {cache: true}).then(({data}) => {
        if (init) {
          state.loaded.projects.init = true;
          state.project.list = [];

          if (state.partnerShipDetail.projectCount > 0 && !data.body.length && state.args.projectType !== "invest") {
            return router.replace(`/partnership/${state.contestSeq}/invest`);
          }
        } else {
          state.loaded.projects.more = true;
        }

        for (let p of data.body) {
          state.project.list.push(p);
        }

        if (data.body.length < state.args.pageUnit) {
          state.loaded.completed = true;
        }
      });
    };

    const onScroll = () => {
      if (state.loaded.projects.init && state.loaded.projects.more) {
        const windowScrollEnd = window.innerHeight + window.pageYOffset;
        const $card = cardsRef.value.querySelector("li .card");

        if (!$card) {
          return;
        }

        const firstCardHeight = $card.offsetHeight;
        const scrollEnd = cardsRef.value.offsetTop + cardsRef.value.offsetHeight;

        scrollEnd - windowScrollEnd <= firstCardHeight && load();
      }
    };

    const change = () => {
      load(true);
    };

    const share = (platform) => {
      const url = location.href;
      const title = document.title;
      const desc = state.partnerShipDetail.contestExplanationText;
      const imgUrl = computedThumbnailUrl.value;

      lib.loadImage(imgUrl, (imgWidth, imgHeight) => {
        store.dispatch("share", {platform, title, desc, url, imgUrl, imgWidth, imgHeight});
      });
    };

    const init = () => {
      state.tabs = [];
      state.contestSeq = router.app.$route.params.contestSeq;
      state.args.tagFilterName = router.app.$route.query.tag;

      if (!router.app.$route.params.projectType) {
        return router.replace(`/partnership/${state.contestSeq}/reward?map`);
      }

      store.dispatch("initSnsShare");

      const projectType = router.app.$route.params.projectType;

      const args = {
        tagFilterName: state.args.tagFilterName,
        sorting: state.args.sorting,
        preview: router.app.$route.query.preview,
        projectType,
      };

      state.loaded.header = false;
      http.get(`/api/partnerships/${state.contestSeq}`, args, {cache: true}).then(({data}) => {
        state.loaded.header = true;

        state.rewardProjectTotCnt = data.body.rewardProjectTotCnt;
        state.investProjectTotCnt = data.body.investProjectTotCnt;
        state.partnerContestList = data.body.partnerContestList;
        state.loanSummary = data.body.loanSummary;

        if (projectType === "mock") {
          state.mockProjectTotCnt = data.body.mockProjectTotCnt;
          state.mockContestInfo = data.body.mockContestInfo;

          if (state.mockContestInfo?.projectStateHideYn === "Y") {
            state.args.sorting = "name";
          }
        }

        if (Array.isArray(data.body.contestTagFilterList)) {
          state.contestTagFilterList = data.body.contestTagFilterList;
        }

        state.partnerShipDetail = data.body.partnerShipDetail;
        state.partnerShipDetail.fundingStartDate = lib.getDateFormat(state.partnerShipDetail.fundingStartDate, "yyyy년 MM월 dd일");
        state.partnerShipDetail.fundingEndDate = lib.getDateFormat(state.partnerShipDetail.fundingEndDate, "yyyy년 MM월 dd일");
        state.partnerShipDetail.partnerLogoPaths = [];

        if (state.partnerShipDetail.contestExplanationText) {
          state.partnerShipDetail.contestExplanationText = lib.getUrlToAnchorReplaced(state.partnerShipDetail.contestExplanationText);
        }

        store.dispatch("setDocumentTitle", state.partnerShipDetail.contestName);

        if (state.rewardProjectTotCnt > 0) {
          state.tabs.push({name: "reward", title: "후원형"});
        }

        if (state.investProjectTotCnt > 0) {
          state.tabs.push({name: "invest", title: "증권형"});
        }

        if (state.loanSummary?.projectCount > 0) {
          state.loanProjectTotCnt = state.loanSummary.projectCount;
          state.tabs.push({name: "loan", title: "대출형"});
        }

        for (let i = 1; i <= 5; i += 1) {
          if (state.partnerShipDetail[`partnerLogo${i}Path`]) {
            state.partnerShipDetail.partnerLogoPaths.push(definitions.urls.imgCdn + state.partnerShipDetail[`partnerLogo${i}Path`]);
          }
        }

        if (router.app.$route.query?.map !== undefined) {
          showMap();
        }

        load(true);
      });
    };

    watch(() => router.app.$route.query, () => {
      window.scrollTo(0, 0);
      init();
    });

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {
      component,
      state,
      cardsRef,
      move,
      computedPartnerContestList,
      load,
      computedCurrencyUnit,
      computedSorting,
      computedTagFilter,
      computedSuwonContestSeq,
      computedSuwonMapContest,
      computedThumbnailUrl,
      sort,
      sorts,
      filter,
      showMap,
      change,
      shares,
      share,
    };
  }
});
</script>

<style lang="scss" scoped>
.partner-reward {
  .header {
    background: $colorBackground;
    padding: $px30 0 $px21 0;

    .row > div {
      &.left {
        .poster {
          display: inline-block;
          width: 100%;
        }

        .share {
          text-align: center;
          margin-top: $px20;
          min-height: $px45;

          button {
            display: inline-block;
            cursor: pointer;
            width: $px40;
            padding: 0;
            background: none;
            color: inherit;
            border: none;
            font: inherit;
            outline: inherit;
            margin: 0 $px5;

            img {
              opacity: 0.9;
              transition: opacity 0.18s;
              width: 100%;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }

      &.right {
        padding-top: $px9;

        select {
          position: absolute;
          top: $px6;
          right: $px15;
          width: auto;
          max-width: $px222;
          height: $px42;
          background-color: rgba(255, 255, 255, 0.9);
        }

        > .wrapper {
          position: relative;

          .title, .duration {
            padding-bottom: $px15;
            word-break: keep-all;
          }

          .title {
            font-size: $px22;
            max-width: $px560;
          }

          .desc {
            white-space: pre-line;
            word-break: keep-all;
            overflow-wrap: anywhere;
            min-height: $px120;
          }

          .logos {
            padding-top: $px20;

            img {
              margin: $px20 $px10 0 $px10;
              max-height: $px40;
            }
          }
        }
      }
    }
  }

  .article {
    padding: $px25 0 $px40 0;

    .status {
      padding-top: $px15;
      padding-bottom: $px25;
      margin: 0 auto;
      max-width: 991px;

      .part {
        position: relative;
        padding-left: $px72;
        float: left;
        width: calc(100% / 3 - 1px);

        .img {
          width: $px58;
          height: $px58;
          position: absolute;
          top: 0;
          left: 0;
        }

        .subject {
          font-size: $px18;
          font-weight: 600;
        }

        .cnt {
          > b {
            display: inline-block;
            width: auto;
            margin-right: $px3;
            font-size: $px20;
          }

          > span {
            display: inline-block;
            width: auto;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .line {
      > .container > div {
        height: $px1;
        background: #eee;
      }
    }

    .tabs {
      text-align: center;
      padding-top: $px35;

      ul > li {
        display: inline-block;
        margin: $px5;
        padding: $px8;

        a {
          text-decoration: none;

          &:hover {
            font-weight: 500;
          }
        }

        &.active {
          position: relative;

          > a {
            font-weight: 500;
          }

          &:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 50%;
            width: $px20;
            height: $px2;
            margin-left: $px-10;
            background: $colorPoint;
          }
        }
      }
    }

    .tags {
      padding-top: $px20;

      > .container > ul {
        margin: 0 $px-5;

        > li {
          display: inline-block;
          padding: 0 $px5;

          > a {
            padding: $px10 $px20;
          }
        }
      }
    }

    .banner {
      padding-top: $px20;

      > .container > .img {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: $colorBackground;
        padding-top: calc(100% / 7);
        width: 100%;
      }
    }

    .cards {
      padding-top: $px20;

      > .container {
        .acts {
          > .wrapper {
            .side {
              padding-bottom: $px3;

              &.left {
                float: left;

                > a {
                  margin-right: $px15;
                }
              }

              &.right {
                float: right;

                .dropdown-menu {
                  left: auto !important;
                  right: 0 !important;

                  .dropdown-item {
                    &:active, &.on {
                      color: #fff;
                      background: $colorPoint;
                    }
                  }
                }
              }
            }
          }
        }

        > ul {
          margin: 0 $px-15;

          li {
            float: left;
            width: calc(100% / 3 - 0.1px);
            padding: $px15 $px15 $px16 $px15;
          }
        }

        .more {
          padding: $px50 $px15 $px20 $px15;
          text-align: center;

          button {
            padding: $px13 $px20;
          }
        }
      }

      &.hexa > .container > ul > li {
        width: calc(100% / 6 - 0.1px);
      }
    }
  }

  &.suwon-map {
    .header .row > div.right > .wrapper {
      padding-right: $px315;

      .map {
        position: absolute;
        top: $px50;
        right: 0;

        img {
          width: $px300;
          height: $px263;
        }

        .area {
          outline: none;
        }
      }
    }
  }

  @media(min-width: 992px) {
    .header .row > div {
      &.left {
        padding-right: 0;
      }

      &.right {
        padding-left: $px53;
      }
    }

    .article {
      .status {
        padding-left: $px100;
        margin-bottom: $px15;
      }
    }
  }

  @media(max-width: 1199px) {
    .header .row > div.right > .wrapper .title {
      max-width: $px420;
    }
  }

  @media(max-width: 991px) {
    &.suwon-map .header .row > div.right > .wrapper {
      padding-right: 0;

      .map {
        position: static;
        padding: $px15 0;
      }
    }

    .article {
      .status {
        padding-top: 0;
        padding-bottom: $px15;
      }

      .tags > .container > ul {
        margin: 0 $px-2;

        > li {
          padding: $px3;

          a {
            font-size: $px14;
          }
        }
      }
    }
  }

  .header.skeleton .row > div.right {
    .title b,
    .duration > span {
      @include skeleton;
    }

    .desc {
      > span, div > b {
        @include skeleton;
      }
    }
  }

  .article.skeleton {
    .status .part {
      .img, .subject > span {
        @include skeleton;
      }

      .cnt {
        b, span {
          @include skeleton;
        }
      }
    }

    .cards > .container .acts > .wrapper .side.left > a {
      @include skeleton;
    }
  }

  @media(max-width: 991px) {
    .header .row > div {
      &.left {
        > .poster {
          display: block;
          text-align: center;
          width: $px140;
          height: $px140;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;;
          }
        }
      }

      &.right {
        padding-top: $px20;
        text-align: center;

        select {
          position: static;
          width: 100%;
          margin: $px10 auto 0 auto;
        }

        > .wrapper {
          .title {
            font-size: $px18;
            max-width: none;
          }

          .duration, .desc {
            font-size: $px14;
          }

          .desc {
            white-space: normal;
            line-height: 1.6;
            min-height: auto;
          }

          .logos {
            text-align: center;
            padding-top: $px10;
            padding-bottom: $px10;

            img {
              margin-top: $px10;
            }
          }
        }
      }
    }

    .article {
      padding-top: $px15;

      .status {
        .part {
          padding: $px5 $px50;
          width: 100%;

          .img {
            display: none;
          }

          .subject {
            display: inline-block;
            font-size: $px16;
          }

          .cnt {
            float: right;

            b {
              font-size: $px16;
            }
          }
        }
      }

      .cards > .container > ul li {
        width: 100% !important;
      }
    }
  }
}
</style>